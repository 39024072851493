import React, { useEffect, useRef, useState } from "react";

import moment from "moment";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { Tooltip, IconButton, Modal, Backdrop } from "@mui/material";
import { GoogleMap, Marker } from "@react-google-maps/api";

import axios from "../../axoisConfig";
import Error404 from "../Error";
import { useStateContext } from "../../context/ContextProvider";
import Loader from "../../Components/Loader";
import { load } from "../App";

import { BiBed, BiBath } from "react-icons/bi";
import { BsImages, BsFiles, BsPen, BsFileEarmarkText } from "react-icons/bs";
import {
  FaCity,
  FaGlobe,
  FaMap,
  FaRulerCombined,
  FaUserPlus,
} from "react-icons/fa";
import { MdLocationPin, MdClose } from "react-icons/md";
import {
  TbCurrentLocation,
  TbPhone,
  TbMail,
  TbUserCircle,
} from "react-icons/tb";
import SelectImagesModal from "./SelectImagesModal";
import SelectDocumentModal from "./SelectDocumentModal";
import EditListingModal from "../../Components/Leads/listings/EditListingComponent";
import SingleImageModal from "./SingleImageModal";
import SingleDocModal from "./SingleDocModal";
import usePermission from "../../utils/usePermission";
import { IoIosVideocam } from "react-icons/io";
import UpdateListModal from "./UpdateListModal";

const SingleListingsModal = ({
  ListingData,
  handleCloseSingleListingModel,
  singleListingModelOpen,
  FetchListings,
  currentPage,
}) => {
  console.log("single listing Data:: ", ListingData);
  const fileInputRef = useRef(null);

  const handleImageSelect = () => {
    fileInputRef.current.click();
  };
  const [loading, setloading] = useState(true);
  const [listData, setListingData] = useState({});
  const [developers, setDevelopers] = useState([]);
  const [openEdit, setOpenEdit] = useState({
    open: false,
    data: null,
    type: null,
  });
  const [leadNotFound, setLeadNotFound] = useState(false);
  const { hasPermission } = usePermission();
  const closingTImeoutId = useRef(null);
  const [singleImageModal, setSingleImageModal] = useState({
    isOpen: false,
    url: "",
    id: null,
  });
  const [singleDocModal, setSingleDocModal] = useState({
    isOpen: false,
    url: "",
    id: null,
  });
  const [selectImagesModal, setSelectImagesModal] = useState({
    isOpen: false,
    listingId: null,
  });
  const [selectDocumentModal, setSelectDocumentModal] = useState({
    isOpen: false,
    listingId: null,
  });
  const {
    currentMode,
    setopenBackDrop,
    BACKEND_URL,
    isArabic,
    isLangRTL,
    i18n,
    User,
    t,
  } = useStateContext();

  const handleEdit = (type) => {
    setOpenEdit({ open: true, data: listData, type: type });
  };

  const [isClosing, setIsClosing] = useState(false);
  const handleClose = () => {
    setIsClosing(true);
    closingTImeoutId.current = setTimeout(() => {
      setIsClosing(false);
      handleCloseSingleListingModel();
    }, 1000);
  };

  // const { lid } = useParams();
  const lid = ListingData;
  console.log("lid ===================", lid);

  const openDoc = (open, url) => {
    window.open(url, "__blank");
  };

  let lat = "";
  let long = "";

  const updateBanner = async (e) => {
    const file = e.target.files[0];

    const data = {
      banner: file,
      new_listing_id: listData?.id,
      long_description: listData?.meta_tags_for_listings?.long_description,
      year_build_in: listData?.meta_tags_for_listings?.year_build_in,
      is_featured: listData?.meta_tags_for_listings?.is_featured,
      meta_title: listData?.meta_tags_for_listings?.meta_title,
      meta_keywords: listData?.meta_tags_for_listings?.meta_keywords,
      meta_description: listData?.meta_tags_for_listings?.meta_description,
    };

    console.log("data to be sended ::: ", data);
    try {
      setloading(true);
      const token = localStorage.getItem("auth-token");
      const updateBanner = await axios.post(
        `${BACKEND_URL}/meta-tags-for-listings/${listData?.meta_tags_for_listings?.id}`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + token,
          },
        }
      );

      console.log("update banner: ", updateBanner);

      setloading(false);
      fetchSingleListing();
      FetchListings(currentPage);
    } catch (error) {
      setloading(false);
      console.log("Error", error);
      const errors = error.response?.data?.errors;

      if (errors) {
        const errorMessages = Object.values(errors).flat().join(" ");
        toast.error(`Errors: ${errorMessages}`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error("Something Went Wrong! Please Try Again", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };
  const fetchSingleListing = async () => {
    try {
      setloading(true);
      const token = localStorage.getItem("auth-token");
      const listing = await axios.get(
        `${BACKEND_URL}/listings/${lid}?images=1&documents=1`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );

      console.log("SINGLE Listings: ", listing);
      setListingData(listing?.data?.data);
      setloading(false);
    } catch (error) {
      setloading(false);
      console.log("Error", error);
      if (error?.response?.status === 404) {
        setLeadNotFound(true);
      } else {
        toast.error("Something went wrong!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  useEffect(() => {
    setopenBackDrop(false);
    fetchSingleListing(lid);
    return () => {
      clearTimeout(closingTImeoutId.current);
    };
  }, []);

  const mapContainerStyle = {
    width: "100%",
    height: "100%",
  };

  const options = {
    disableDefaultUI: true,
    zoomControl: true,
    mapTypeControl: true,
  };

  if (listData?.latlong) {
    const splitLatLong = listData?.latlong?.split(",");
    lat = splitLatLong[0];
    long = splitLatLong[1];
  }

  console.log("maps: ", load);

  const style = {
    transform: "translate(0%, 0%)",
    boxShadow: 24,
  };

  return (
    <>
      {/* <div
        className={`flex min-h-screen w-full p-4 ${
          !themeBgImg && (currentMode === "dark" ? "bg-black" : "bg-white")
        } ${currentMode === "dark" ? "text-white" : "text-black"}`}
      > */}
      <Modal
        keepMounted
        open={singleListingModelOpen}
        // onClose={handleCloseTimelineModel}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div
          className={`${
            isLangRTL(i18n.language) ? "modal-open-left" : "modal-open-right"
          } ${
            isClosing
              ? isLangRTL(i18n.language)
                ? "modal-close-left"
                : "modal-close-right"
              : ""
          }
          w-[100vw] h-[100vh] flex items-start justify-end `}
        >
          <button
            // onClick={handleCloseTimelineModel}
            onClick={handleClose}
            className={`${
              isLangRTL(i18n.language) ? "rounded-r-full" : "rounded-l-full"
            }
            bg-primary w-fit h-fit p-3 my-4 z-10`}
          >
            <MdClose
              size={18}
              color={"white"}
              className=" hover:border hover:border-white hover:rounded-full"
            />
          </button>

          <div
            style={style}
            className={` ${
              currentMode === "dark"
                ? "bg-[#1C1C1C] text-white"
                : "bg-[#FFFFFF] text-black"
            } ${
              currentMode === "dark" &&
              (isLangRTL(i18n.language)
                ? "border-primary border-r-2"
                : "border-primary border-l-2")
            }
              p-4 h-[100vh] w-[80vw] overflow-y-scroll
              `}
          >
            {loading ? (
              <Loader />
            ) : (
              <>
                {leadNotFound ? (
                  <Error404 />
                ) : (
                  <div className="w-full">
                    {/* BANNER  */}
                    <div className="w-full  mb-3  relative">
                      {listData?.banner_img ? (
                        <>
                          <img
                            onClick={() =>
                              setSingleImageModal({
                                isOpen: true,
                                url: listData?.banner_img,
                                listingId: listData?.id,
                              })
                            }
                            src={listData?.banner_img}
                            alt={"banner"}
                            className="w-full h-[350px] object-cover m-1 rounded-md"
                          />
                          <div className="absolute top-0 right-1">
                            <Tooltip title="Edit Banner" arrow>
                              <IconButton
                                className={`rounded-full bg-btn-primary`}
                                onClick={handleImageSelect}
                              >
                                <BsPen size={16} color={"#FFFFFF"} />
                              </IconButton>
                            </Tooltip>
                            <input
                              type="file"
                              ref={fileInputRef}
                              style={{ display: "none" }}
                              accept="image/*"
                              onChange={updateBanner}
                            />
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>

                    <div className={`w-full py-5`}>
                      <div className="grid sm:grid-cols-1 md:grid-cols-2">
                        <div className="w-full p-1">
                          <div className="flex items-center">
                            <div
                              className={`bg-primary rounded-md text-white p-2 ${
                                isLangRTL(i18n.language) ? "ml-2" : "mr-2"
                              } font-semibold`}
                            >
                              {listData?.price}
                            </div>
                            <h1
                              className={`text-lg font-bold mx-2 ${
                                currentMode === "dark"
                                  ? "text-white"
                                  : "text-black"
                              }`}
                              style={{
                                fontFamily: isArabic(listData?.listing_title)
                                  ? "Noto Kufi Arabic"
                                  : "inherit",
                              }}
                            >
                              {listData?.listing_title}
                            </h1>
                            <Tooltip title="Edit Listing Details" arrow>
                              <IconButton
                                className={`rounded-full bg-btn-primary`}
                                onClick={() => handleEdit("main")}
                              >
                                <BsPen size={16} color={"#FFFFFF"} />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </div>
                        <div className="w-full p-1">
                          <div className="flex items-center gap-2 justify-end">
                            {/* EDIT DETAILS  */}
                            {/* <Tooltip title="Edit Listing Details" arrow>
                              <IconButton
                                className={`rounded-full bg-btn-primary`}
                                onClick={handleEdit}
                              >
                                <BsPen size={16} color={"#FFFFFF"} />
                              </IconButton>
                            </Tooltip> */}

                            <p
                              className={`${
                                listData?.status == 1
                                  ? "text-green-600"
                                  : "text-red-600"
                              } p-2 font-semibold rounded-md shadow-sm`}
                            >
                              {listData?.listing_status == 1
                                ? "Active"
                                : "Inactive"}
                            </p>

                            {/* WATCH VIDEO  */}
                            {listData?.promo_video && (
                              <Tooltip title="Promo Video" arrow>
                                <IconButton
                                  onClick={() => {
                                    window.open(
                                      listData?.promo_video,
                                      "_blank"
                                    );
                                  }}
                                  className={`rounded-full bg-btn-primary`}
                                >
                                  <IoIosVideocam size={16} color={"#FFFFFF"} />
                                </IconButton>
                              </Tooltip>
                            )}

                            <div className="mx-1"></div>

                            <div className="border border-primary p-2 font-semibold rounded-md shadow-sm">
                              {listData?.listing_type}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="grid sm:grid-cols-1 md:grid-cols-6 lg:grid-cols-6 gap-5 p-4">
                        <div className="sm:col-span-1 md:col-span-3 lg:col-span-4 space-y-3">
                          {/* SHORT DESCRIPTION  */}
                          {/* <div className="flex gap-3 mb-3">
                            <h6>{listData?.description} </h6>
                          </div> */}
                          {/* COUNTRY  */}
                          <div className="flex gap-3">
                            <FaGlobe
                              size={18}
                              className={
                                currentMode === "dark"
                                  ? "text-[#EEEEEE]"
                                  : "text-[#333333]"
                              }
                            />
                            <h6>
                              {listData?.country}, {listData?.city}
                            </h6>
                          </div>
                          <div className="flex gap-3">
                            <FaRulerCombined
                              size={18}
                              className={
                                currentMode === "dark"
                                  ? "text-[#EEEEEE]"
                                  : "text-[#333333]"
                              }
                            />
                            <h6>
                              {listData?.size} {listData?.size_unit}
                            </h6>
                          </div>
                        </div>

                        <div className="sm:col-span-1 md:col-span-3 lg:col-span-2 space-y-2 text-right gap-5 mb-5">
                          <div className="flex items-end justify-end h-full w-full ">
                            <div className="text-right">
                              {/* ADDRESS  */}
                              <div className="flex gap-3 mb-3">
                                <TbCurrentLocation
                                  size={18}
                                  className={
                                    currentMode === "dark"
                                      ? "text-[#EEEEEE]"
                                      : "text-[#333333]"
                                  }
                                />
                                {listData?.nearby?.length > 0 &&
                                  listData?.nearby?.map((nearby) => (
                                    <h6>{nearby?.name}, </h6>
                                  ))}
                              </div>
                              {/* Bedrooms  */}
                              <div className="flex items-center gap-3 mb-3">
                                <Tooltip title="Edit attribute " arrow>
                                  <IconButton
                                    className={`rounded-full bg-btn-primary`}
                                    onClick={() => handleEdit("list_attr")}
                                  >
                                    <BsPen size={10} color={"#FFFFFF"} />
                                  </IconButton>
                                </Tooltip>
                                <BiBed
                                  size={18}
                                  className={
                                    currentMode === "dark"
                                      ? "text-[#EEEEEE]"
                                      : "text-[#333333]"
                                  }
                                />
                                <h6>{listData?.bedrooms}</h6>
                                {/* <h6>
                                  {listData?.listing_type === "null"
                                    ? "-"
                                    : listData?.listing_type?.name}
                                </h6> */}
                              </div>
                              {/* baths  */}
                              <div className="flex gap-3 mb-3">
                                <BiBath
                                  size={18}
                                  className={
                                    currentMode === "dark"
                                      ? "text-[#EEEEEE]"
                                      : "text-[#333333]"
                                  }
                                />
                                <h6>
                                  {listData?.bathrooms === "null"
                                    ? "-"
                                    : listData?.bathrooms}
                                </h6>
                              </div>
                              <p className="text-sm my-3">
                                Listing added on{" "}
                                {moment(listData?.created_at).format(
                                  "YYYY-MM-DD HH:MM"
                                )}
                              </p>
                              <p className="text-sm my-2 flex items-center">
                                <FaUserPlus
                                  size={16}
                                  color={`${
                                    currentMode === "dark"
                                      ? "#EEEEEE"
                                      : "#333333"
                                  }`}
                                  className="mx-2"
                                />
                                {listData?.user?.name}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* IN MAP  */}

                    {listData?.latlong === null || listData?.latlong === "" ? (
                      <></>
                    ) : (
                      <div className="w-full h-[50vh] border border-primary relative">
                        {!load?.isLoaded ? (
                          <div>Your map is loading...</div>
                        ) : (
                          <GoogleMap
                            zoom={12}
                            center={{
                              lat: parseFloat(lat),
                              lng: parseFloat(long),
                            }}
                            mapContainerStyle={mapContainerStyle}
                            options={options}
                          >
                            <Marker
                              key={listData?.id}
                              position={{
                                lat: Number(parseFloat(lat)),
                                lng: Number(parseFloat(long)),
                              }}
                              icon={{
                                url: (
                                  <MdLocationPin size={30} color={"#DA1F26"} />
                                ),
                                scaledSize: window.google.maps
                                  ? new window.google.maps.Size(50, 50)
                                  : null,
                              }}
                            />
                          </GoogleMap>
                        )}
                        <div className="absolute top-0 right-1">
                          <Tooltip title="Edit attrbiute type" arrow>
                            <IconButton
                              className={`rounded-full bg-btn-primary`}
                              onClick={() => handleEdit("list_attr_type")}
                            >
                              <BsPen size={16} color={"#FFFFFF"} />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </div>
                    )}

                    {/* <div className="bg-primary h-0.5 w-full my-5"></div> */}

                    {listData?.description && (
                      <>
                        <div className="w-full flex items-center pb-3">
                          <div
                            className={`bg-primary h-10 w-1 rounded-full ${
                              isLangRTL(i18n.language) ? "ml-2" : "mr-2"
                            } my-1`}
                          ></div>
                          <h1
                            className={`text-lg font-semibold ${
                              currentMode === "dark"
                                ? "text-white"
                                : "text-black"
                            }`}
                          >
                            {t("description")}
                          </h1>
                          <Tooltip title="Edit listing meta" arrow>
                            <IconButton
                              className={`rounded-full bg-btn-primary ml-3`}
                              onClick={() => handleEdit("list_meta")}
                            >
                              <BsPen size={16} color={"#FFFFFF"} />
                            </IconButton>
                          </Tooltip>
                        </div>

                        <p style={{ maxHeight: "200px", overflowY: "auto" }}>
                          {listData?.description}
                        </p>
                      </>
                    )}

                    {(listData?.addedBy === User?.id ||
                      hasPermission("seller_details") ||
                      User.role === 1) && (
                      <div className={`w-full py-5`}>
                        <div className="grid sm:grid-cols-1 md:grid-cols-6 lg:grid-cols-6 gap-5">
                          {/* SELLER DETAILS
                          <div className="sm:col-span-1 md:col-span-3 lg:col-span-2">
                            <div className="w-full flex items-center pb-3">
                              <div
                                className={`bg-primary h-10 w-1 rounded-full ${
                                  isLangRTL(i18n.language) ? "ml-2" : "mr-2"
                                } my-1`}
                              ></div>
                              <h1
                                className={`text-lg font-semibold ${
                                  currentMode === "dark"
                                    ? "text-white"
                                    : "text-black"
                                }`}
                              >
                                {t("label_seller_details")}
                              </h1>
                            </div>

                            <div className="space-y-3">
                              <div className="flex gap-3">
                                <TbUserCircle
                                  size={18}
                                  className={
                                    currentMode === "dark"
                                      ? "text-[#EEEEEE]"
                                      : "text-[#333333]"
                                  }
                                />
                                <h6>{listData?.user?.name}</h6>
                              </div>

                              <div className="flex gap-3">
                                <TbMail
                                  size={18}
                                  className={
                                    currentMode === "dark"
                                      ? "text-[#EEEEEE]"
                                      : "text-[#333333]"
                                  }
                                />
                                <h6>
                                  {listData?.user?.email === "null"
                                    ? ""
                                    : listData?.user?.email}
                                </h6>
                              </div>
                            </div>
                          </div> */}
                          <div className="sm:col-span-1 md:col-span-3 lg:col-span-4 ">
                            {/* IMAGES */}
                            <div
                              className={`absolute rounded-xl shadow-sm p-4`}
                            >
                              <div className="w-full flex items-center gap-x-1 mb-3 overflow-x-scroll">
                                {listData?.images?.map((pic) =>
                                  pic ? (
                                    <img
                                      onClick={() =>
                                        setSingleImageModal({
                                          isOpen: true,
                                          url: pic,

                                          listingId: listData?.id,
                                        })
                                      }
                                      src={pic}
                                      alt={pic}
                                      className="w-auto h-[200px] object-cover m-1 rounded-md cursor-pointer"
                                    />
                                  ) : (
                                    <></>
                                  )
                                )}
                              </div>
                              {/* )} */}
                              <div className="absolute top-0 right-1">
                                <Tooltip title="Edit Banner" arrow>
                                  <IconButton
                                    className={`rounded-full bg-btn-primary`}
                                    onClick={() =>
                                      setSelectImagesModal({
                                        isOpen: true,
                                        listingId: listData?.id,
                                      })
                                    }
                                  >
                                    <BsPen size={16} color={"#FFFFFF"} />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            </div>

                            {/* DOCUMENT */}
                            <div
                              className={`absolute rounded-xl shadow-sm p-4`}
                            >
                              <div className="w-full flex items-center gap-x-1 mb-3 overflow-x-scroll">
                                {listData?.documents?.map((doc) =>
                                  doc ? (
                                    <div className="flex w-full justify-center">
                                      <div className="relative w-min">
                                        <div
                                          onClick={() => {
                                            window.open(doc?.doc_url, "_blank");
                                          }}
                                          className="p-2 flex items-center justify-center hover:cursor-pointer"
                                        >
                                          <a
                                            href={doc?.doc_url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            <div className="w-full text-center">
                                              <div className="w-full flex justify-center">
                                                <BsFileEarmarkText
                                                  size={70}
                                                  color={"#AAAAAA"}
                                                  className="hover:-mt-1 hover:mb-1"
                                                />
                                              </div>
                                              <div className="my-3">
                                                {doc?.doc_name}
                                              </div>
                                            </div>
                                          </a>
                                        </div>
                                        {/* {hasPermission(
                                          "property_delete_doc"
                                        ) && (
                                          <div className="absolute top-0 -right-4 p-1 cursor-pointer">
                                            <IconButton
                                              className="bg-btn-primary"
                                              onClick={() =>
                                                handleDeleteDocument(doc?.id)
                                              }
                                            >
                                              {btnLoading ? (
                                                <CircularProgress />
                                              ) : (
                                                <BsTrash
                                                  size={20}
                                                  color={
                                                    currentMode === "dark"
                                                      ? "#ffffff"
                                                      : "#000000"
                                                  }
                                                />
                                              )}
                                            </IconButton>
                                          </div>
                                        )} */}
                                      </div>
                                    </div>
                                  ) : (
                                    <></>
                                  )
                                )}
                              </div>
                              {/* )} */}
                              <div className="absolute top-0 right-1">
                                <Tooltip title="Edit Banner" arrow>
                                  <IconButton
                                    className={`rounded-full bg-btn-primary`}
                                    onClick={() =>
                                      setSelectImagesModal({
                                        isOpen: true,
                                        listingId: listData?.id,
                                      })
                                    }
                                  >
                                    <BsPen size={16} color={"#FFFFFF"} />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {/* <Footer /> */}

                {singleImageModal?.isOpen && (
                  <SingleImageModal
                    singleImageModal={singleImageModal}
                    handleClose={() => setSingleImageModal({ isOpen: false })}
                    fetchSingleListing={fetchSingleListing}
                    listing="true"
                  />
                )}

                {singleDocModal?.isOpen && (
                  <SingleDocModal
                    singleImageModal={singleDocModal}
                    handleClose={() => setSingleDocModal({ isOpen: false })}
                    fetchSingleListing={fetchSingleListing}
                  />
                )}

                {selectImagesModal?.isOpen && (
                  <SelectImagesModal
                    fetchSingleListing={fetchSingleListing}
                    selectImagesModal={selectImagesModal}
                    handleClose={() => setSelectImagesModal({ isOpen: false })}
                    listData={listData}
                  />
                )}
                {selectDocumentModal?.isOpen && (
                  <SelectDocumentModal
                    fetchSingleListing={fetchSingleListing}
                    selectDocumentModal={selectDocumentModal}
                    handleClose={() =>
                      setSelectDocumentModal({ isOpen: false })
                    }
                  />
                )}
                {openEdit?.open && (
                  <UpdateListModal
                    openEdit={openEdit}
                    setOpenEdit={setOpenEdit}
                    fetchSingleListing={fetchSingleListing}
                    handleClose={() => setOpenEdit(false)}
                    FetchListings={FetchListings}
                    developers={developers}
                    setDevelopers={setDevelopers}
                  />
                )}
                {/* {openEdit && (
                  <EditListingModal
                    setOpenEdit={setOpenEdit}
                    openEdit={openEdit}
                    fetchSingleListing={fetchSingleListing}
                    handleClose={() => setOpenEdit(false)}
                  />
                )} */}
              </>
            )}
          </div>
        </div>
      </Modal>
      {/* </div> */}
    </>
  );
};

export default SingleListingsModal;
