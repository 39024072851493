import { Button } from "@material-tailwind/react";
import {
  Backdrop,
  Box,
  CircularProgress,
  IconButton,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import Select from "react-select";
import axios from "../../axoisConfig";
import moment from "moment";
import React, { useEffect, useState, useRef } from "react";
import { IoMdClose } from "react-icons/io";
import { toast } from "react-toastify";
import { useStateContext } from "../../context/ContextProvider";
import "react-phone-number-input/style.css";
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from "react-phone-number-input";
import classNames from "classnames";
import { selectStyles } from "../_elements/SelectStyles";
import {
  enquiry_options,
  language_options,
  property_options,
  purpose_options,
} from "../_elements/SelectOptions";
import { MdClose } from "react-icons/md";
import HeadingTitle from "../_elements/HeadingTitle";

const UpdateLead = ({
  LeadModelOpen,
  setLeadModelOpe,
  handleLeadModelOpen,
  handleLeadModelClose,
  lead_origin,
  LeadData,
  FetchLeads,
}) => {
  console.log("Single Lead: ", LeadData);
  const {
    darkModeColors,
    currentMode,
    User,
    BACKEND_URL,
    setSalesPerson: setAllSalesPersons,
    SalesPerson: AllSalesPersons,
    t,
    Managers,
    isLangRTL,
    i18n,
    primaryColor,
    // SalesPerson,
    themeBgImg
  } = useStateContext();
  const closingTimeoutId = useRef(null);
  const [value, setValue] = useState();
  const [altContact,setAltContact] = useState()
  const [loading, setloading] = useState(true);
  const [btnloading, setbtnloading] = useState(false);
  const [noAgents, setNoAgents] = useState(false);
  const [error, setError] = useState(false);
  const [altContactError,setAltContactError] = useState(false)
  const style = {
    transform: "translate(0%, 0%)",
    boxShadow: 24,
  };
  const [isClosing, setIsClosing] = useState(false);

  const [PropertyType, setPropertyType] = useState("");
  const [EnquiryType, setEnquiryType] = useState("");
  const [ForType, setForType] = useState("");
  const [LanguagePrefered, setLanguagePrefered] = useState("");
  const [LeadStatus, setLeadStatus] = useState("");
  // eslint-disable-next-line
  const [Feedback, setFeedback] = useState("");
  const [Manager, setManager] = useState("");
  const [SalesPerson, setSalesPerson] = useState([]);
  const [SalesPerson2, setSalesPerson2] = useState("");
  const [LeadName, setLeadName] = useState("");
  const [LeadContact, setLeadContact] = useState("");
  const [LeadAltContact,setLeadAltContact] = useState("")
  const [LeadEmail, setLeadEmail] = useState("");
  const [LeadAltEmail,setLeadAltEmail] = useState("");
  const [LeadProject, setLeadProject] = useState("");
  const [LeadNotes, setLeadNotes] = useState("");

  const [emailError, setEmailError] = useState(false);
  const [altEmailError,setAltEmailError] = useState(false)
  const [developer,setDeveloper] = useState(null)
  const [vendors,setVendors] = useState([])
  const handlePhone = (isAlt) => {
    // setError(false);
    // let inputValue = value;
    // console.log("Phone: ", inputValue);
    // if (inputValue && isPossiblePhoneNumber(inputValue)) {
    //   console.log("Possible: ", inputValue);
    //   if (isValidPhoneNumber(inputValue)) {
    //     setLeadContact(formatPhoneNumberIntl(inputValue));
    //     console.log("Valid: ", LeadContact);
    //     setError(false);
    //   } else {
    //     setError("Not a valid number.");
    //   }
    // } else {
    //   setError("Not a valid number.");
    // }
    if(isAlt){
      setAltContactError(false);
    }else{
      setError(false)
    }
    const inputValue = isAlt ? altContact:value;
    console.log("Phone: ", inputValue);
    if (inputValue && isPossiblePhoneNumber(inputValue)) {
      console.log("Possible: ", inputValue);
      if (isValidPhoneNumber(inputValue)) {
        if(isAlt){
        setLeadAltContact(formatPhoneNumberIntl(inputValue));

        }else{

          setLeadContact(formatPhoneNumberIntl(inputValue));
        }
        

        console.log("Valid lead contact: ", LeadContact);
        console.log("Valid input: ", inputValue);
        if(isAlt){
          setAltContactError(false)
        }else{
          setError(false);

        }
      } else {
        if(isAlt){
          setAltContactError("Not a valid number.")
        }else{
          setError("Not a valid number.");
        }
      }
    } else {
      if(isAlt){
        setAltContactError("Not a valid number")
      }else{
        setError("Not a valid number.");

      }
    }
  };
  const handleAltEmail = (e) => {
    
    setAltEmailError(false);
    const value = e.target.value;
    console.log(value);
    // const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

    const emailRegex = /^[A-Za-z0-9._+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

    if (emailRegex.test(value)) {
      setAltEmailError(false);
    } else {
      setAltEmailError("Kindly enter a valid email.");
      // setLeadEmail("");
      return;
    }
    setLeadAltEmail(value);
    console.log("Email state: ", LeadAltEmail);
  };

  const ChangePropertyType = (event) => {
    setPropertyType(event.value);
  };
  const ChangeEnquiryType = (event) => {
    setEnquiryType(event.value);
  };
  const ChangeForType = (event) => {
    setForType(event.value);
  };
  const ChangeLanguagePrefered = (event) => {
    setLanguagePrefered(event.value);
  };
  // eslint-disable-next-line
  const ChangeLeadStatus = (event) => {
    setLeadStatus(event.target.value);
  };
  // eslint-disable-next-line
  const ChangeFeedback = (event) => {
    setFeedback(event.target.value);
  };

  const handleEmail = (e) => {
    setEmailError(false);
    const value = e.target.value;
    console.log(value);
    const emailRegex = /^[A-Za-z0-9._+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

    if (emailRegex.test(value)) {
      setEmailError(false);
    } else {
      setEmailError("Kindly enter a valid email.");
    }
    setLeadEmail(value);
    console.log("Email state: ", LeadEmail);
  };

  async function setPersons(urls) {
    const token = localStorage.getItem("auth-token");
    const requests = urls.map((url) =>
      axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
    );
    const responses = await Promise.all(requests);
    const data = {};
    for (let i = 0; i < responses.length; i++) {
      const response = responses[i];
      if (response.data?.team[0]?.isParent) {
        const name = `manager-${response.data.team[0].isParent}`;
        data[name] = response.data.team;
      }
    }
    setAllSalesPersons(data);
  }

  // useEffect(() => {
  //   const token = localStorage.getItem("auth-token");
  //   axios
  //     .get(`${BACKEND_URL}/managers`, {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: "Bearer " + token,
  //       },
  //     })
  //     .then((result) => {
  //       console.log(result);
  //       const managers = result?.data?.managers?.data;
  //       setManager2(managers || []);

  //       const urls = managers?.map((manager) => {
  //         return `${BACKEND_URL}/teamMembers/${manager?.id}`;
  //       });

  //       setPersons(urls || []);
  //       setloading(false);
  //     })
  //     .catch((err) => {
  //       setloading(false);
  //       console.log(err);
  //     });
  // }, []);

  const fetchVendors = async(val) =>{
    let token = localStorage?.getItem("auth-token")
    try{
     const vendors =  await axios.get(`${BACKEND_URL}/vendors`,{
        headers:{
          Authorization:"Bearer "+token
        },
        params:{
          type:"developer",
          vendor_name:val || ""
        }
  
      })
  
      // const developers = vendors?.data?.data?.filter(vendor=>vendor?.toLowerCase() ==="developer")
  
      setVendors(vendors?.data?.data?.data)
  
    }catch(error){
      console.log(error,"error occured in api ")
    }
  }
  
  
      useEffect(()=>{
       fetchVendors()
      },[])

  useEffect(() => {
    const token = localStorage.getItem("auth-token");

    // GETTING LEAD DETAILS
    axios
      .get(`${BACKEND_URL}/leads/${LeadData.leadId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((result) => {
        console.log("the lead details is given by");
        console.log(result);
        // setlead(result?.data?.data);
        setPropertyType(result?.data?.data?.leadType);
        setEnquiryType(result?.data?.data?.enquiryType);
        setLeadProject(result?.data?.data?.project);
        setForType(result?.data?.data?.leadFor);
        setLeadName(result?.data?.data?.leadName);

        let leadContact = result?.data?.data?.leadContact?.replaceAll(" ", "")

        if (leadContact && !leadContact.startsWith("+")) {
          leadContact = "+" + leadContact;
        }

        setLeadContact(leadContact);
        let leadAltContact = result?.data?.data?.leadAltContact?.replaceAll(" ", "")

        if (leadAltContact && !leadAltContact.startsWith("+")) {
          leadAltContact = "+" + leadAltContact;
        }

        setLeadAltContact(leadAltContact);
        setLeadAltEmail(result?.data?.data?.leadAltEmail)
        setDeveloper(result?.data?.data?.developer)
        // setLeadContact(result?.data?.data?.leadContact);
        setLeadEmail(result?.data?.data?.leadEmail);
        setLanguagePrefered(result?.data?.data?.language);
        setLeadStatus(result?.data?.data?.leadStatus);
        setFeedback(result?.data?.data?.feedback);
        setLeadNotes(result?.data?.data?.notes);
        setManager(result?.data?.data?.assignedToManager);
        setSalesPerson2(result?.data?.data?.assignedToSales);

        setloading(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error in Fetching the Lead", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        handleLeadModelClose();
      });
    // eslint-disable-next-line
  }, []);

  const handleClose = () => {
    setIsClosing(true);
    closingTimeoutId.current = setTimeout(() => {
      setIsClosing(false);
      handleLeadModelClose();
    }, 1000);
  };

  useEffect(() => {
    const agents = AllSalesPersons[`manager-${Manager}`];
    if (agents === undefined) {
      setNoAgents(true);
    } else {
      setNoAgents(false);
      setSalesPerson(agents);
    }
    // eslint-disable-next-line
    return () => {
      clearTimeout(closingTimeoutId.current);
    };
  }, [Manager]);

  const UpdateLeadFunc = async () => {
    setbtnloading(true);

    if (!LeadContact) {
      setloading(false);
      toast.error("Contact number is required.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setbtnloading(false);

      return;
    }

    const token = localStorage.getItem("auth-token");
    const UpdateLeadData = new FormData();
    // UpdateLeadData.append("id", User.id);
    UpdateLeadData.append("id", LeadData.leadId);
    // UpdateLeadData.append("lid", LeadData.leadId);
    UpdateLeadData.append("leadName", LeadName);
    UpdateLeadData.append("leadContact", LeadContact?.replaceAll(" ", ""));
    UpdateLeadData.append("leadAltContact", LeadAltContact?.replaceAll(" ", ""));
    UpdateLeadData.append("leadEmail", LeadEmail);
    UpdateLeadData.append("leadAltEmail", LeadAltEmail);
    if(developer){
      UpdateLeadData.append("developer", developer?.value);
    }
    UpdateLeadData.append("enquiryType", EnquiryType);
    UpdateLeadData.append("leadType", PropertyType);
    UpdateLeadData.append("project", LeadProject);
    UpdateLeadData.append("leadFor", ForType);
    UpdateLeadData.append("language", LanguagePrefered);
    UpdateLeadData.append("leadStatus", LeadStatus);
    UpdateLeadData.append("notes", LeadNotes);
    if (User.role === 1 || User.role === 3) {
      console.log("manager and salesperson ", Manager, SalesPerson2);
    }

    // UpdateLeadData.append("last", LeadNotes);

    await axios
      .post(`${BACKEND_URL}/leads/${LeadData.leadId}`, UpdateLeadData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((result) => {
        console.log("lead updated successfull");
        console.log(result);
        toast.success("Lead Updated Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        FetchLeads(token);
        setbtnloading(false);
        handleLeadModelClose();
      })
      .catch((err) => {
        toast.error("Error in Updating the Lead", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,

          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setbtnloading(false);
      });
  };
  return (
    <>
      {/* MODAL FOR SINGLE LEAD SHOW */}
      <Modal
        keepMounted
        open={LeadModelOpen}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div
          className={`${
            isLangRTL(i18n.language) ? "modal-open-left" : "modal-open-right"
          } ${
            isClosing
              ? isLangRTL(i18n.language)
                ? "modal-close-left"
                : "modal-close-right"
              : ""
          }
        w-[100vw] h-[100vh] flex items-start justify-end`}
        >
          <button
            // onClick={handleLeadModelClose}
            onClick={handleClose}
            className={`${
              isLangRTL(i18n.language) ? "rounded-r-full" : "rounded-l-full"
            }
            bg-primary w-fit h-fit p-3 my-4 z-10`}
          >
            <MdClose
              size={18}
              color={"white"}
              className="hover:border hover:border-white hover:rounded-full"
            />
          </button>
          <div
            style={style}
            className={` ${
              currentMode === "dark"
                ? "bg-dark text-white"
                : "bg-light text-black"
            } ${
              currentMode === "dark" &&
              (isLangRTL(i18n.language)
                ? "border-r-2 border-primary"
                : "border-l-2 border-primary")
            }
             p-5 h-[100vh] w-[85vw] overflow-y-scroll 
            `}
          >
            {loading ? (
              <div className="w-full flex items-center justify-center space-x-1">
                <CircularProgress size={20} />
                <span className="font-semibold text-lg">
                  {" "}
                  {t("fetching_your_lead")}
                </span>
              </div>
            ) : (
              <>
                <HeadingTitle title={t("update_lead_details")} />

                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    UpdateLeadFunc();
                  }}
                >
                  <div className="grid sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-5">
                    <Box
                      sx={{
                        ...darkModeColors,
                        "& .MuiFormLabel-root, .MuiInputLabel-root, .MuiInputLabel-formControl":
                          {
                            right: isLangRTL(i18n.language)
                              ? "2.5rem"
                              : "inherit",
                            transformOrigin: isLangRTL(i18n.language)
                              ? "right"
                              : "left",
                          },
                        "& legend": {
                          textAlign: isLangRTL(i18n.language)
                            ? "right"
                            : "left",
                        },
                      }}
                      className={`${
                        currentMode === "dark" ? "bg-dark-neu" : "bg-light-neu"
                      } p-5`}
                    >
                      <h4
                        className={`${
                          currentMode === "dark"
                            ? "text-primary"
                            : "text-primary"
                        } text-center font-semibold pb-5 uppercase`}
                      >
                        {t("agent_details")}
                      </h4>

                      {/* MANAGER  */}
                      <TextField
                        id="Manager"
                        type="text"
                        label={t("label_manager")}
                        className="w-full"
                        style={{
                          marginBottom: "20px",
                          pointerEvents: "none",
                        }}
                        variant="outlined"
                        size="small"
                        value={
                          Managers?.find((person) => person?.id === Manager)
                            ?.userName || t("no_manager")
                        }
                        onChange={(e) => {
                          e.preventDefault();
                        }}
                        readOnly={true}
                      />

                      {/* AGENT  */}
                      {noAgents
                        ? ""
                        : (User.role === 1 || User.role === 3) && (
                            <>
                              <TextField
                                id="Salesperson"
                                type="text"
                                label={t("label_agent")}
                                className="w-full"
                                style={{
                                  marginBottom: "20px",
                                  pointerEvents: "none",
                                }}
                                variant="outlined"
                                size="small"
                                value={
                                  SalesPerson?.find(
                                    (person) => person?.id === SalesPerson2
                                  )?.userName || t("no_agent_assigned")
                                }
                                onChange={(e) => {
                                  e.preventDefault();
                                }}
                                readOnly={true}
                              />
                            </>
                          )}

                      {User.role === 7 && (
                        <TextField
                          id="Salesperson"
                          label={t("label_agent")}
                          type="text"
                          className="w-full mb-5"
                          style={{
                            marginBottom: "20px",
                            pointerEvents: "none",
                          }}
                          variant="outlined"
                          size="small"
                          value={User?.userName}
                          readOnly={true}
                        />
                      )}
                    </Box>

                    <Box
                      sx={{
                        ...darkModeColors,
                        "& .MuiFormLabel-root, .MuiInputLabel-root, .MuiInputLabel-formControl":
                          {
                            right: isLangRTL(i18n.language)
                              ? "2.5rem"
                              : "inherit",
                            transformOrigin: isLangRTL(i18n.language)
                              ? "right"
                              : "left",
                          },
                        "& legend": {
                          textAlign: isLangRTL(i18n.language)
                            ? "right"
                            : "left",
                        },
                      }}
                      className={`${
                        currentMode === "dark" ? "bg-dark-neu" : "bg-light-neu"
                      } p-5`}
                    >
                      <h4
                        className={`${
                          currentMode === "dark"
                            ? "text-primary"
                            : "text-primary"
                        } text-center font-semibold pb-5 uppercase`}
                      >
                        {t("project_details")}
                      </h4>
                      {/* PROJECT NAME  */}
                      <Select
                  id="developer"
                  aria-label="select developer"
                  options={vendors?.map(vendor=>({label:vendor?.vendor_name,value:vendor?.id}))}
                  value={developer}
                  onInputChange={(val,{action})=>{
                   if(action === 'input-change') {
                    fetchVendors(val)
                   }     
                  }}
                  onChange={(e)=>setDeveloper(e)}
                  placeholder={t("form_developer_name")}
                  className={`mb-5`}
                  styles={selectStyles(currentMode, primaryColor)}
                  // menuIsOpen={true}
                />
                      <TextField
                        id="Project"
                        type={"text"}    
                        className="w-full"
                        style={{
                          marginBottom: "20px",
                        }}
                        label={t("label_project_name")}
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                          style: { zIndex: 0,} // Ensure the label does not interfere
                        }}
                        value={LeadProject}
                        onChange={(e) => setLeadProject(e.target.value)}
                      />
                      {/* ENQUIRY  */}
                      <Select
                        id="enquiry"
                        value={enquiry_options(t).find(
                          (option) => option.value === EnquiryType
                        )}
                        onChange={ChangeEnquiryType}
                        options={enquiry_options(t)}
                        placeholder={t("label_enquiry_about")}
                        className="w-full"
                        menuPortalTarget={document.body}
                        styles={selectStyles(currentMode, primaryColor)}
                      />

                      {/* <TextField
                          id="enquiry"
                          value={EnquiryType}
                          label={t("label_enquiry_for")}
                          onChange={ChangeEnquiryType}
                          size="small"
                          className="w-full"
                          sx={{
                            marginBottom: "1.25rem !important",
                          }}
                          displayEmpty
                          select
                        >
                          <MenuItem value="">
                            {t("label_enquiry_about")}
                          </MenuItem>
                          <MenuItem value={"Studio"}>
                            {t("enquiry_studio")}
                          </MenuItem>
                          <MenuItem value={"1 Bedroom"}>
                            {t("enquiry_1bed")}
                          </MenuItem>
                          <MenuItem value={"2 Bedrooms"}>
                            {t("enquiry_2bed")}
                          </MenuItem>
                          <MenuItem value={"3 Bedrooms"}>
                            {t("enquiry_3bed")}
                          </MenuItem>
                          <MenuItem value={"4 Bedrooms"}>
                            {t("enquiry_4bed")}
                          </MenuItem>
                          <MenuItem value={"5 Bedrooms"}>
                            {t("enquiry_5bed")}
                          </MenuItem>
                          <MenuItem value={"6 Bedrooms"}>
                            {t("enquiry_6bed")}
                          </MenuItem>
                          <MenuItem value={"Retail"}>
                            {t("enquiry_retail")}
                          </MenuItem>
                          <MenuItem value={"Other"}>
                            {t("enquiry_others")}
                          </MenuItem>
                        </TextField> */}

                      {/* PROPERTY TYPE  */}
                      <Select
                        id="property-type"
                        value={property_options(t).find(
                          (option) => option.value === PropertyType
                        )}
                        onChange={ChangePropertyType}
                        options={property_options(t)}
                        placeholder={t("label_property_type")}
                        className="w-full"
                        menuPortalTarget={document.body}
                        styles={selectStyles(currentMode, primaryColor)}
                      />
                      {/* <TextField
                          id="property-type"
                          value={PropertyType}
                          label={t("label_property_type")}
                          sx={{
                            marginBottom: "1.25rem !important",
                          }}
                          onChange={ChangePropertyType}
                          size="small"
                          className="w-full"
                          displayEmpty
                          select
                        >
                          <MenuItem value="">
                            {t("label_property_type")}
                          </MenuItem>
                          <MenuItem value={"Apartment"}>
                            {t("property_apartment")}
                          </MenuItem>
                          <MenuItem value={"Villa"}>
                            {t("property_villa")}
                          </MenuItem>
                          <MenuItem value={"penthouse"}>
                            {t("property_penthouse")}
                          </MenuItem>
                          <MenuItem value={"mansion"}>
                            {t("property_mansion")}
                          </MenuItem>
                          <MenuItem value={"Commercial"}>
                            {t("property_commercial")}
                          </MenuItem>
                          <MenuItem value={"Townhouse"}>
                            {t("property_townhouse")}
                          </MenuItem>
                        </TextField> */}

                      {/* PURPOSE  */}
                      <Select
                        id="for"
                        value={purpose_options(t).find(
                          (option) => option.value === ForType
                        )}
                        onChange={ChangeForType}
                        options={purpose_options(t)}
                        placeholder={t("label_purpose_of_enquiry")}
                        className="w-full"
                        menuPortalTarget={document.body}
                        styles={selectStyles(currentMode, primaryColor)}
                      />
                      {/* <TextField
                          id="for"
                          sx={{
                            marginBottom: "1.25rem !important",
                          }}
                          value={ForType}
                          label={t("label_purpose_of_enquiry")}
                          onChange={ChangeForType}
                          size="small"
                          className="w-full"
                          displayEmpty
                          select
                        >
                          <MenuItem value="" selected>
                            For
                          </MenuItem>
                          <MenuItem value={"Investment"}>
                            {t("purpose_investment")}
                          </MenuItem>
                          <MenuItem value={"End-user"}>
                            {t("purpose_end_user")}
                          </MenuItem>
                        </TextField> */}
                    </Box>

                    <Box
                      sx={{
                        ...darkModeColors,
                        "& .MuiFormLabel-root, .MuiInputLabel-root, .MuiInputLabel-formControl":
                          {
                            right: isLangRTL(i18n.language)
                              ? "2.5rem"
                              : "inherit",
                            transformOrigin: isLangRTL(i18n.language)
                              ? "right"
                              : "left",
                          },
                        "& legend": {
                          textAlign: isLangRTL(i18n.language)
                            ? "right"
                            : "left",
                        },
                      }}
                      className={`${
                        currentMode === "dark" ? "bg-dark-neu" : "bg-light-neu"
                      } p-5`}
                    >
                      <h4
                        className={`${
                          currentMode === "dark"
                            ? "text-primary"
                            : "text-primary"
                        } text-center font-semibold pb-5 uppercase`}
                      >
                        {t("lead_details")}
                      </h4>
                      {/* LEAD NAME  */}
                      <TextField
                        id="LeadName"
                        type={"text"}
                        label={t("label_lead_name")}
                        className="w-full"
                        style={{ marginBottom: "20px" }}
                        variant="outlined"
                        size="small"
                        required
                        value={LeadName}
                        onChange={(e) => setLeadName(e.target.value)}
                      />

                      {/* CONTACT  */}
                      <PhoneInput
                        placeholder={t("label_contact_number")}
                        value={value}
                        onChange={(value) => setValue(value)}
                        onKeyUp={()=>handlePhone(false)}
                        required
                        labels={t("label_phone_number")}
                        error={error}
                        className={`
                          ${classNames({
                            "dark-mode": currentMode === "dark",
                            "phone-input-light": currentMode !== "dark",
                            "phone-input-dark": currentMode === "dark",
                          })}
                           mb-5`}
                        size="small"
                        style={{
                          marginBottom: "20px",
                          color: currentMode === "dark" ? "white" : "black",
                          border: `1px solid ${
                            currentMode === "dark" ? "#fff" : "#ccc"
                          }`,
                          borderRadius: "5px",
                          outline: "none",
                        }}
                        inputStyle={{
                          outline: "none",
                        }}
                      />
                      {error && (
                        <Typography variant="body2" color="error">
                          {error}
                        </Typography>
                      )}
                       <PhoneInput
                  placeholder={t("label_alt_contact_number")}
                  aria-label="contact number"
                  value={altContact}
                  onChange={(value) => setAltContact(value)}
                  onKeyUp={()=>handlePhone(true)}
                  error={altContactError}
                  className={` ${classNames({
                    "dark-mode": currentMode === "dark",
                    "phone-input-light": currentMode !== "dark",
                    "phone-input-dark": currentMode === "dark",
                  })} mb-5`}
                  size="small"
                  style={{
                    background: `${
                      !themeBgImg
                        ? currentMode === "dark"
                          ? "#000000"
                          : "#FFFFFF"
                        : "transparent"
                      // : (currentMode === "dark" ? blurDarkColor : blurLightColor)
                    }`,
                    "& .PhoneInputCountryIconImg": {
                      color: "#fff",
                    },
                    color: currentMode === "dark" ? "white" : "black",
                    border: `1px solid ${
                      currentMode === "dark" ? "#EEEEEE" : "#666666"
                    }`,
                    borderRadius: "5px",
                    outline: "none",
                  }}
                  inputStyle={{
                    outline: "none !important",
                  }}
                />

                {altContactError && (
                  <Typography variant="body2" color="error">
                    {altContactError}
                  </Typography>
                )}

                      {/* EMAIL  */}
                      <TextField
                        id="LeadEmailAddress"
                        type={"email"}
                        className="w-full"
                        label={t("label_email_address")}
                        style={{ marginBottom: "20px" }}
                        variant="outlined"
                        size="small"
                        value={LeadEmail === "undefined" ? "" : LeadEmail}
                        // onChange={(e) => setLeadEmail(e.target.value)}
                        error={emailError && emailError}
                        helperText={emailError && emailError}
                        onChange={handleEmail}
                      />
                       <TextField
                  id="altLeadEmailAddress"
                  aria-label="alternative lead email address"
                  type={"email"}
                  label={t("label_alt_email_address")}
                  className="w-full"
                  sx={{
                    "&": {
                      marginBottom: "1.25rem !important",
                    },
                  }}
                  variant="outlined"
                  size="small"
                  error={altEmailError && altEmailError}
                  helperText={altEmailError && altEmailError}
                  // value={LeadEmail}
                  onChange={handleAltEmail}
                />

                      {/* LANGUAGE  */}
                      <Select
                        id="LanguagePrefered"
                        value={language_options.find(
                          (option) => option.value === LanguagePrefered
                        )}
                        onChange={ChangeLanguagePrefered}
                        options={language_options}
                        placeholder={t("label_language")}
                        className="w-full"
                        menuPortalTarget={document.body}
                        styles={selectStyles(currentMode, primaryColor)}
                      />
                      {/* <TextField
                          sx={{
                            marginBottom: "1.25rem !important",
                          }}
                          id="LanguagePrefered"
                          value={LanguagePrefered}
                          onChange={ChangeLanguagePrefered}
                          label={t("label_language")}
                          size="small"
                          className="w-full"
                          select
                          displayEmpty
                        >
                          <MenuItem value="" selected>
                            {t("label_language")}
                          </MenuItem>
                          <MenuItem value={"Arabic"}>Arabic</MenuItem>
                          <MenuItem value={"English"}>English</MenuItem>
                          <MenuItem value={"Farsi"}>Farsi</MenuItem>
                          <MenuItem value={"French"}>French</MenuItem>
                          <MenuItem value={"Hindi"}>Hindi</MenuItem>
                          <MenuItem value={"Russian"}>Russian</MenuItem>
                          <MenuItem value={"Spanish"}>Spanish</MenuItem>
                          <MenuItem value={"Urdu"}>Urdu</MenuItem>
                        </TextField> */}
                    </Box>
                  </div>
                  <button
                    className={`${
                      currentMode === "dark"
                        ? "bg-primary-dark-neu"
                        : "bg-primary-light-neu"
                    } w-full my-5 text-white py-3 font-semibold disabled:opacity-50 disabled:cursor-not-allowed `}
                    // ripple={true}
                    // size="lg"
                    // type="submit"
                    disabled={btnloading ? true : false}
                  >
                    {btnloading ? (
                      <div className="flex items-center justify-center space-x-1 mt-5">
                        <CircularProgress size={18} sx={{ color: "white" }} />
                      </div>
                    ) : (
                      <span> {t("btn_update_lead")}</span>
                    )}
                  </button>
                </form>
              </>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default UpdateLead;
