import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  MenuItem,
  Modal,
  Switch,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useStateContext } from "../../../context/ContextProvider";
import Select from "react-select";
import { selectStyles } from "../../../Components/_elements/SelectStyles";
import { toast } from "react-toastify";
import axios from "../../../axoisConfig";
import {
  currencies,
  listing_options,
  listing_status,
} from "../../_elements/SelectOptions";
import { CiCirclePlus } from "react-icons/ci";
import { FaPlus } from "react-icons/fa";

const Addlisting = ({
  listData,
  listingIds,
  setListingIDs,
  handleNext,
  edit,
  handleClose,
  fetchSingleListing,
  listingData,
  setListingData,
}) => {
  const {
    darkModeColors,
    currentMode,
    User,
    BACKEND_URL,
    isArabic,
    primaryColor,
    t,
    isLangRTL,
    i18n,
    fontFam,
    themeBgImg,
  } = useStateContext();

  console.log("list data::: ", listData);

  const token = localStorage.getItem("auth-token");

  const [btnLoading, setBtnLoading] = useState(false);
  const [addBtnLoading, setAddBtnLoading] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [userLoading, setUserLoading] = useState(false);
  const [cityList, setCityList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityLoading, setCityLoading] = useState(false);
  const [displayForm, setDisplayForm] = useState({
    isOpen: false,
    type: null,
  });

  console.log("disply form:: ", displayForm);

  const [countryData, setCountryData] = useState({
    name: null,
    code: null,
    country_id: null,
    state_id: null,
  });
  const [bannerPreview, setBannerPreview] = useState(null);

  // const [listingData, setlistingData] = useState({
  //   title: listData?.title || "",
  //   listing_type_id: edit
  //     ? listData?.listing_type?.id
  //     : listingIds?.listing_type_id,
  //   listing_attribute_id: edit
  //     ? listData?.listing_attribute?.id
  //     : listingIds?.listing_attribute_id,
  //   listing_arrtibute_type_id: edit
  //     ? listData?.listing_attribute_type?.id
  //     : listingIds?.listing_arrtibute_type_id,
  //   country_id: listData?.country?.id || "",
  //   state_id: listData?.state?.id || "",
  //   city_id: listData?.city?.id || "",
  //   short_description: listData?.short_description || "",
  //   status: listData?.status || "",
  // });

  const handleChange = (e) => {
    setListingData((prevListingAttr) => ({
      ...prevListingAttr,
      [e.target.id]: e.target.value,
    }));
  };

  const handleData = (e) => {
    setCountryData({
      ...countryData,
      [e.target.id]: e.target.value,
    });
  };

  const handleCloseModal = () => {
    setDisplayForm({ isOpen: false, type: null });
  };

  const handleVideoUpload = (e) => {
    const file = e.target.files[0];

    if (file) {
      // Check if the file is an MP4
      const isMp4 = file.type === "video/mp4";
      if (!isMp4) {
        toast.error(`Only mp4 video type is allowed`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return;
      }

      // Check if the file size is less than or equal to 1MB
      const isFileSizeValid = file.size <= 2 * 1024 * 1024; // 1MB in bytes
      if (!isFileSizeValid) {
        toast.error(`File size must be under 1mb.`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return;
      }

      setListingData({
        ...listingData,
        promo_video: file,
      });
    }
  };

  const fetchCountries = () => {
    axios
      .get(`${BACKEND_URL}/countries`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + token,
        },
      })
      .then((result) => {
        console.log("countries list : ", result);
        setCountryList(result?.data?.data);
      })
      .catch((err) => {
        console.error(err);
        setBtnLoading(false);
        console.log(err);
        const errors = err.response?.data?.errors;

        if (errors) {
          const errorMessages = Object.values(errors).flat().join(" ");
          toast.error(`Errors: ${errorMessages}`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.error("Unable to fetch countries", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      });
  };

  const FetchCitynState = () => {
    setCityLoading(true);

    const country_id = listingData.country_id || countryData?.country_id;

    const citiesApi = axios.get(`${BACKEND_URL}/cities`, {
      params: { country_id },
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    const statesApi = axios.get(`${BACKEND_URL}/states`, {
      params: { country_id },
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    Promise.all([citiesApi, statesApi])
      .then(([citiesResult, statesResult]) => {
        console.log("Cities: ", citiesResult.data);
        console.log("States: ", statesResult.data);

        setCityList(citiesResult?.data?.data);
        setStateList(statesResult?.data?.data);
        setCityLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setCityLoading(false);
        const error = err.response?.data?.message;

        if (error) {
          toast.error(` ${error}`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.error("Unable to fetch city and state", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      });
  };

  const AddListings = () => {
    setBtnLoading(true);

    let url = edit
      ? `${BACKEND_URL}/new-listings/${listData?.id}`
      : `${BACKEND_URL}/new-listings`;

    let method = edit ? "put" : "post";

    axios({
      method: method,
      url: url,
      data: listingData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((result) => {
        console.log("listing  added : ", result);
        setBtnLoading(false);

        toast.success(
          ` List ${edit ? "updated" : "added"} added successfully.`,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );

        if (edit) {
          handleClose();
          fetchSingleListing();

          return;
        }

        const listingID = result?.data?.data?.id;
        const shortDesc = result?.data?.data?.short_description;
        setListingIDs({
          ...listingIds,
          new_listing_id: listingID,
          meta_description: shortDesc,
        });

        setListingData({
          ...listingData,
          title: "",
          country_id: "",
          state_id: "",
          city_id: "",
          short_description: "",
          status: "",
        });
        handleNext();
      })
      .catch((err) => {
        console.error(err);
        setBtnLoading(false);
        console.log(err);
        const errors = err.response?.data?.errors;

        if (errors) {
          const errorMessages = Object.values(errors).flat().join(" ");
          toast.error(`Errors: ${errorMessages}`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.error("Something Went Wrong! Please Try Again", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      });
  };

  const AddCountry = () => {
    setAddBtnLoading(true);

    let data;

    if (displayForm?.type === "country") {
      data = {
        name: countryData?.name,
        code: countryData?.code,
      };
    } else if (displayForm?.type === "state") {
      data = {
        name: countryData?.name,
        country_id: countryData?.country_id,
      };
    } else if (displayForm?.type === "city") {
      data = {
        name: countryData?.name,
        country_id: countryData?.country_id,
        state_id: countryData?.state_id,
      };
    }

    let url =
      displayForm?.type === "country"
        ? `${BACKEND_URL}/countries`
        : displayForm?.type === "state"
        ? `${BACKEND_URL}/states`
        : `${BACKEND_URL}/cities`;

    axios({
      method: "post",
      url: url,
      data: data,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((result) => {
        console.log(`${displayForm?.type} added`, result);
        setAddBtnLoading(false);

        toast.success(` ${displayForm?.type} added successfully.`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        setCountryData({
          name: null,
          code: null,
          country_id: null,
          state_id: null,
        });

        displayForm?.type === "country" ? fetchCountries() : FetchCitynState();
        handleCloseModal();
      })
      .catch((err) => {
        console.error(err);
        setAddBtnLoading(false);
        console.log(err);
        const errors = err.response?.data?.errors;

        if (errors) {
          const errorMessages = Object.values(errors).flat().join(" ");
          toast.error(`Errors: ${errorMessages}`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.error("Something Went Wrong! Please Try Again", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      });
  };

  useEffect(() => {
    fetchCountries();
  }, []);
  useEffect(() => {
    if (
      listingData?.country_id ||
      (countryData?.country_id && displayForm?.type === "city")
    ) {
      FetchCitynState();
    }
  }, [listingData?.country_id, countryData?.country_id]);

  return (
    <div className="my-4">
      <h4 className={`text-primary text-center font-semibold pb-5`}>
        {t("heading_add_listing")}
      </h4>

      <div className="grid sm:grid-cols-1   md:grid-cols-2 lg:grid-cols-2 gap-3">
        <Box
          sx={{
            ...darkModeColors,
            "& .MuiFormLabel-root, .MuiInputLabel-root, .MuiInputLabel-formControl":
              {
                right: isLangRTL(i18n.language) ? "2.5rem" : "inherit",
                transformOrigin: isLangRTL(i18n.language) ? "right" : "left",
              },
            "& legend": {
              textAlign: isLangRTL(i18n.language) ? "right" : "left",
            },
          }}
        >
          <TextField
            id="listing_title"
            type={"text"}
            label={t("title")}
            className="w-full"
            sx={{
              marginBottom: "20px !important",
            }}
            variant="outlined"
            size="small"
            value={listingData?.listing_title}
            name="projectLocation"
            onChange={handleChange}
            required
          />

          <TextField
            id="description"
            type={"text"}
            label={t("description")}
            className="w-full"
            sx={{
              marginBottom: "20px !important",
            }}
            variant="outlined"
            size="small"
            value={listingData?.description}
            name="area"
            onChange={handleChange}
            required
          />

          <Select
            id="type"
            value={listing_options(t)?.find(
              (list_type) =>
                list_type.value?.toLowerCase() ===
                listingData?.type?.toLowerCase()
            )}
            onChange={(e) => {
              setListingData({
                ...listingData,
                listing_type: e.value,
              });
            }}
            options={listing_options(t)?.map((list_attr_type) => ({
              value: list_attr_type.value,
              label: list_attr_type.label,
            }))}
            className="w-full"
            placeholder={t("label_listing_type")}
            menuPortalTarget={document.body}
            styles={selectStyles(currentMode, primaryColor)}
          />

          <input
            accept="video/*"
            style={{ display: "none" }}
            id="promo-video-file"
            type="file"
            name="video"
            onChange={handleVideoUpload}
          />
          <label htmlFor="promo-video-file">
            <Button
              variant="contained"
              size="lg"
              className="bg-main-red-color w-full bg-btn-primary text-white rounded-lg py-3 border-primary font-semibold my-3"
              style={{
                fontFamily: fontFam,
                color: "#ffffff",
                marginBottom: "20px",
              }}
              component="span"
            >
              <span>{t("label_promo_video")}</span>
            </Button>
            <p className="text-primary mt-2 italic">
              {listingData?.promo_video ? `Promo video selected.` : null}
            </p>
          </label>

          {bannerPreview ? (
            <div className="w-max rounded-lg border">
              <img
                src={bannerPreview}
                width="100px"
                height="100px"
                alt="Banner Preview"
              />
            </div>
          ) : null}

          <input
            accept="image/*"
            style={{ display: "none" }}
            id="banner-image-file"
            type="file"
            name="picture"
            onChange={(e) => {
              console.log("event of banner image: ", e);

              const image = e.target.files[0]; // Single file selection
              const reader = new FileReader();

              reader.onload = () => {
                setBannerPreview(reader.result); // Set the preview URL for the single image
                setListingData({
                  ...listingData,
                  banner_img: image, // Store the selected image in the state
                });
              };

              if (image) {
                reader.readAsDataURL(image); // Read the image file and trigger the onload event
              }
            }}
          />

          <label htmlFor="banner-image-file">
            <Button
              variant="contained"
              size="lg"
              className="bg-main-red-color w-full bg-btn-primary text-white rounded-lg py-3 border-primary font-semibold my-3"
              style={{
                fontFamily: fontFam,
                color: "#ffffff",
                // marginTop: "px",
              }}
              component="span"
            >
              <span>{t("label_banner")}</span>
            </Button>
            <p className="text-primary mt-2 italic">
              {listingData?.banner_img ? `Banner image selected.` : null}
            </p>
          </label>

          {/* 
          <div className="flex items-center space-x-2">
            <Select
              id="country_id"
              value={{
                value: listingData?.country_id,
                label: listingData?.country_id
                  ? countryList?.find(
                      (cont) => cont.id === listingData?.country_id
                    )?.name || ""
                  : t("label_country"),
              }}
              onChange={(e) => {
                setListingData({
                  ...listingData,
                  country_id: e.value,
                });
              }}
              options={countryList?.map((cont) => ({
                value: cont.id,
                label: cont.name,
              }))}
              isLoading={userLoading}
              className="w-full"
              placeholder={t("label_country")}
              menuPortalTarget={document.body}
              styles={selectStyles(currentMode, primaryColor)}
            />
            {displayForm?.isOpen === false ? (
              <IconButton
                onClick={() =>
                  setDisplayForm({
                    type: "country",
                    isOpen: true,
                  })
                }
                className="rounded bg-btn-primary "
              >
                <CiCirclePlus color="#fff" size={22} />
              </IconButton>
            ) : null}
          </div>

          <div className="flex items-center space-x-2">
            <Select
              id="state"
              value={{
                value: listingData?.state_id,
                label: listingData?.state_id
                  ? stateList?.find((cont) => cont.id === listingData?.state_id)
                      ?.name || ""
                  : t("state"),
              }}
              onChange={(e) => {
                setListingData({
                  ...listingData,
                  state_id: e.value,
                });
              }}
              isLoading={cityLoading}
              options={stateList?.map((cont) => ({
                value: cont.id,
                label: cont.name,
              }))}
              className="w-full"
              placeholder={t("state")}
              menuPortalTarget={document.body}
              styles={selectStyles(currentMode, primaryColor)}
            />

            <IconButton
              onClick={() =>
                setDisplayForm({
                  type: "state",
                  isOpen: true,
                })
              }
              className="rounded bg-btn-primary "
            >
              <CiCirclePlus color="#fff" size={22} />
            </IconButton>
          </div>
          <div className="flex items-center space-x-2">
            <Select
              id="city_id"
              value={{
                value: listingData?.city_id,
                label: listingData?.city_id
                  ? cityList?.find((cont) => cont.id === listingData?.city_id)
                      ?.name || ""
                  : t("label_city"),
              }}
              onChange={(e) => {
                setListingData({
                  ...listingData,
                  city_id: e.value,
                });
              }}
              isLoading={cityLoading}
              options={cityList?.map((cont) => ({
                value: cont.id,
                label: cont.name,
              }))}
              className="w-full"
              placeholder={t("label_city")}
              menuPortalTarget={document.body}
              styles={selectStyles(currentMode, primaryColor)}
            />
            <IconButton
              onClick={() =>
                setDisplayForm({
                  type: "city",
                  isOpen: true,
                })
              }
              className="rounded bg-btn-primary "
            >
              <CiCirclePlus color="#fff" size={22} />
            </IconButton>
          </div> */}
        </Box>
        <Box
          sx={{
            ...darkModeColors,
            "& .MuiFormLabel-root, .MuiInputLabel-root, .MuiInputLabel-formControl":
              {
                right: isLangRTL(i18n.language) ? "2.5rem" : "inherit",
                transformOrigin: isLangRTL(i18n.language) ? "right" : "left",
              },
            "& legend": {
              textAlign: isLangRTL(i18n.language) ? "right" : "left",
            },
          }}
        >
          <TextField
            type={"number"}
            // label={t("label_item_price")}
            className="w-full"
            style={{
              marginBottom: "20px",
            }}
            placeholder={t("label_price")}
            variant="outlined"
            name="price"
            size="small"
            id="price"
            value={listingData?.price}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <Box
                  sx={{
                    minWidth: "90px",
                    padding: 0,
                    marginLeft: isLangRTL(i18n.language) ? "0px" : "0px",
                    marginRight: isLangRTL(i18n.language) ? "0px" : "10px",
                  }}
                >
                  <TextField
                    value={
                      currencies(t).find(
                        (option) => option.value === listingData?.currency
                      )?.label
                    }
                    name="currency"
                    onChange={handleChange}
                    options={currencies(t)}
                    placeholder={t("label_unit")}
                    className={`w-full p-0 ${
                      !themeBgImg
                        ? currentMode === "dark"
                          ? "bg-[#333333]"
                          : "bg-[#DDDDDD]"
                        : currentMode === "dark"
                        ? "blur-bg-dark"
                        : "blur-bg-light"
                    } `}
                    size="small"
                    select
                    // menuPortalTarget={document.body}
                    // styles={selectBgStyles(
                    //   currentMode,
                    //   primaryColor,
                    //   blurDarkColor,
                    //   blurLightColor
                    // )}
                  >
                    <MenuItem disabled selected value="">
                      {t("label_select_currency")}
                    </MenuItem>
                    {currencies(t)?.map((currency) => (
                      <MenuItem value={currency?.value}>
                        {currency?.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              ),
            }}
          />
          <div>
            <FormControlLabel
              sx={{ marginBottom: "30px" }}
              control={
                <Checkbox
                  value={listingData?.is_start_price}
                  onClick={(e) => {
                    const value = e.target.value;
                    const isTrue = value == 0 ? 1 : 0;
                    setListingData({
                      ...listingData,
                      is_start_price: isTrue,
                    });
                  }}
                  fullWidth
                  inputProps={{ "aria-label": "controlled" }}
                  style={{
                    color: currentMode === "dark" ? "#fff" : "#000",
                  }}
                />
              }
              label={t("is_start_price")}
            />

            <FormControlLabel
              sx={{ marginBottom: "30px" }}
              control={
                <Checkbox
                  value={listingData?.is_featured}
                  onClick={(e) => {
                    const value = e.target.value;
                    const isTrue = value == 0 ? 1 : 0;
                    setListingData({
                      ...listingData,
                      is_featured: isTrue,
                    });
                  }}
                  fullWidth
                  inputProps={{ "aria-label": "controlled" }}
                  style={{
                    color: currentMode === "dark" ? "#fff" : "#000",
                  }}
                />
              }
              label={t("label_is_featured")}
            />
          </div>
          {/* <Select
            id="status"
            value={listing_status(t)?.find(
              (status) => status.value === listingData?.listing_status
            )}
            onChange={(e) => {
              setListingData({
                ...listingData,
                listing_status: e.value,
              });
            }}
            options={listing_status(t)?.map((status) => ({
              value: status.value,
              label: status.label,
            }))}
            className="w-full"
            placeholder={t("status")}
            menuPortalTarget={document.body}
            styles={selectStyles(currentMode, primaryColor)}
          /> */}

          <FormControlLabel
            sx={{
              marginBottom: "30px",
            }}
            control={
              <Switch
                size="small"
                defaultChecked={1}
                value={listingData?.listing_status}
                onChange={(e) => {
                  const value = e.target.value;
                  const isSelected = value == 1 ? 0 : 1;
                  setListingData({
                    ...listingData,
                    listing_status: isSelected,
                  });
                }}
                sx={{
                  // marginBottom: "20px",
                  color: "green !important",
                  "& .MuiSwitch-thumb": {
                    color:
                      listingData?.listing_status == 1
                        ? "green !important"
                        : "#B91C1C !important",
                  },
                  "& .Mui-checked": {
                    color:
                      listingData?.listing_status == 1
                        ? "green !important"
                        : "#B91C1C !important",
                  },
                  "& .MuiSwitch-track": {
                    backgroundColor:
                      listingData?.listing_status == 1
                        ? "green !important"
                        : "#B91C1C !important",
                  },
                  "& .css-1q0bjt2-MuiSwitch-root .MuiSwitch-thumb": {
                    backgroundColor:
                      listingData?.listing_status == 1
                        ? "green !important"
                        : "#B91C1C !important",
                  },
                }}
              />
            }
            label={t("status")}
          />
        </Box>
      </div>
      {/* <Button
        className={`w-full text-white rounded-md py-3 font-semibold disabled:opacity-50 disabled:cursor-not-allowed hover:shadow-none`}
        ripple={true}
        style={{
          fontFamily: fontFam,
          background: `${primaryColor}`,
        }}
        size="lg"
        type="submit"
        disabled={btnLoading ? true : false}
        onClick={AddListings}
      >
        {btnLoading ? (
          <CircularProgress
            size={20}
            sx={{ color: "white" }}
            className="text-white"
          />
        ) : (
          <span className="text-white">{t("submit")}</span>
        )}
      </Button> */}

      {displayForm?.isOpen && (
        <Modal
          keepMounted
          open={displayForm?.isOpen}
          onClose={handleCloseModal}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
          closeAfterTransition
          // BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <div
            style={{
              transform: "translate(-50%, -50%)",
              boxShadow: 24,
            }}
            className={`w-[calc(100%-20px)] md:w-[40%]  ${
              currentMode === "dark" ? "bg-[#1c1c1c]" : "bg-white"
            } absolute top-1/2 left-1/2 p-5 pt-16 rounded-md`}
          >
            <div className="flex flex-col justify-center items-center">
              <FaPlus size={50} className="text-main-red-color text-2xl" />
              <h1
                className={`font-semibold pt-3 text-lg ${
                  currentMode === "dark" ? "text-white" : "text-dark"
                }`}
              >
                {`${t("btn_add")} ${displayForm?.type}`}
              </h1>

              <Box
                sx={{
                  ...darkModeColors,
                  "& .MuiFormLabel-root, .MuiInputLabel-root, .MuiInputLabel-formControl":
                    {
                      right: isLangRTL(i18n.language) ? "2.5rem" : "inherit",
                      transformOrigin: isLangRTL(i18n.language)
                        ? "right"
                        : "left",
                    },
                  "& legend": {
                    textAlign: isLangRTL(i18n.language) ? "right" : "left",
                  },
                  marginTop: "10px",
                }}
              >
                {displayForm?.type === "country" ? (
                  <>
                    <TextField
                      id="name"
                      type={"text"}
                      label={t("title")}
                      className="w-full mt-4"
                      sx={{
                        marginBottom: "20px !important",
                      }}
                      variant="outlined"
                      size="small"
                      value={countryData?.name}
                      name="projectLocation"
                      onChange={handleData}
                      required
                    />
                    <TextField
                      id="code"
                      type={"text"}
                      label={t("country_code")}
                      className="w-full mt-4"
                      sx={{
                        marginBottom: "20px !important",
                      }}
                      variant="outlined"
                      size="small"
                      value={countryData?.code}
                      name="projectLocation"
                      onChange={handleData}
                      required
                    />
                  </>
                ) : displayForm?.type === "state" ? (
                  <>
                    <Select
                      id="country_id"
                      value={{
                        value: countryData?.country_id,
                        label: countryData?.country_id
                          ? countryList?.find(
                              (cont) => cont.id === countryData?.country_id
                            )?.name || ""
                          : t("label_country"),
                      }}
                      onChange={(e) => {
                        setCountryData({
                          ...countryData,
                          country_id: e.value,
                        });
                      }}
                      options={countryList?.map((cont) => ({
                        value: cont.id,
                        label: cont.name,
                      }))}
                      isLoading={userLoading}
                      className="w-full"
                      placeholder={t("label_country")}
                      menuPortalTarget={document.body}
                      styles={selectStyles(currentMode, primaryColor)}
                    />
                    <TextField
                      id="name"
                      type={"text"}
                      label={t("title")}
                      className="w-full mt-4"
                      sx={{
                        marginBottom: "20px !important",
                      }}
                      variant="outlined"
                      size="small"
                      value={countryData?.name}
                      name="projectLocation"
                      onChange={handleData}
                      required
                    />
                  </>
                ) : (
                  <>
                    <Select
                      id="country_id"
                      value={{
                        value: countryData?.country_id,
                        label: countryData?.country_id
                          ? countryList?.find(
                              (cont) => cont.id === countryData?.country_id
                            )?.name || ""
                          : t("label_country"),
                      }}
                      onChange={(e) => {
                        setCountryData({
                          ...countryData,
                          country_id: e.value,
                        });
                      }}
                      options={countryList?.map((cont) => ({
                        value: cont.id,
                        label: cont.name,
                      }))}
                      isLoading={userLoading}
                      className="w-full"
                      placeholder={t("label_country")}
                      menuPortalTarget={document.body}
                      styles={selectStyles(currentMode, primaryColor)}
                    />
                    <Select
                      id="state_id"
                      value={{
                        value: countryData?.state_id,
                        label: countryData?.state_id
                          ? stateList?.find(
                              (cont) => cont.id === countryData?.state_id
                            )?.name || ""
                          : t("state"),
                      }}
                      onChange={(e) => {
                        setCountryData({
                          ...countryData,
                          state_id: e.value,
                        });
                      }}
                      isLoading={cityLoading}
                      options={stateList?.map((cont) => ({
                        value: cont.id,
                        label: cont.name,
                      }))}
                      className="w-full"
                      placeholder={t("state")}
                      menuPortalTarget={document.body}
                      styles={selectStyles(currentMode, primaryColor)}
                    />

                    <TextField
                      id="name"
                      type={"text"}
                      label={t("title")}
                      className="w-full mt-4"
                      sx={{
                        marginBottom: "20px !important",
                      }}
                      variant="outlined"
                      size="small"
                      value={countryData?.name}
                      name="projectLocation"
                      onChange={handleData}
                      required
                    />
                  </>
                )}
              </Box>
            </div>

            <div className="action buttons mt-5 flex items-center justify-center space-x-2">
              <Button
                className={` text-white rounded-md py-3 font-semibold disabled:opacity-50 disabled:cursor-not-allowed hover:shadow-none bg-main-red-color shadow-none`}
                ripple="true"
                size="lg"
                onClick={(e) => AddCountry(e, displayForm)}
              >
                {addBtnLoading ? (
                  <CircularProgress size={18} sx={{ color: "blue" }} />
                ) : (
                  <span>{t("btn_submit")}</span>
                )}
              </Button>

              <Button
                onClick={handleCloseModal}
                ripple="true"
                variant="outlined"
                className={`shadow-none  rounded-md text-sm  ${
                  currentMode === "dark"
                    ? "text-white border-white"
                    : "text-main-red-color border-main-red-color"
                }`}
              >
                {t("cancel")}
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Addlisting;
