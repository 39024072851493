import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  MenuItem,
  Modal,
  Switch,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useStateContext } from "../../../context/ContextProvider";
import Select from "react-select";
import { selectStyles } from "../../../Components/_elements/SelectStyles";
import { toast } from "react-toastify";
import axios from "../../../axoisConfig";
import {
  currencies,
  listing_options,
  listing_status,
} from "../../_elements/SelectOptions";
import { CiCirclePlus } from "react-icons/ci";
import { FaPlus } from "react-icons/fa";
import LocationCheckBox from "./LocationCheckbox";

const AddListingNearBy = ({
  listData,
  listingIds,
  setListingIDs,
  handleNext,
  edit,
  handleClose,
  fetchSingleListing,
  listingData,
  setListingData,
}) => {
  const {
    darkModeColors,
    currentMode,
    User,
    BACKEND_URL,
    isArabic,
    primaryColor,
    t,
    isLangRTL,
    i18n,
    fontFam,
    themeBgImg,
  } = useStateContext();

  const token = localStorage.getItem("auth-token");

  const [loading, setLoading] = useState(false);
  const [nearByLocations, setNearByLocations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);

  const fetchLocations = () => {
    setLoading(true);

    axios
      .get(`${BACKEND_URL}/nearby`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + token,
        },
      })
      .then((result) => {
        console.log("near by location list : ", result);
        setNearByLocations(result?.data?.data?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        console.log(err);
        const errors = err.response?.data?.errors;

        if (errors) {
          const errorMessages = Object.values(errors).flat().join(" ");
          toast.error(`Errors: ${errorMessages}`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.error("Unable to fetch locations", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      });
  };

  useEffect(() => {
    fetchLocations();
  }, []);

  return (
    <div className="my-4">
      <h4 className={`text-primary text-center font-semibold pb-5`}>
        {t("heading_near_by_locations")}
      </h4>
      {loading ? (
        <div className="w-full h-[400px] flex justify-center items-center">
          <CircularProgress />
        </div>
      ) : (
        <div className="grid sm:grid-cols-1   md:grid-cols-2 lg:grid-cols-2 gap-3">
          {/* <Box
            sx={{
              ...darkModeColors,
              "& .MuiFormLabel-root, .MuiInputLabel-root, .MuiInputLabel-formControl":
                {
                  right: isLangRTL(i18n.language) ? "2.5rem" : "inherit",
                  transformOrigin: isLangRTL(i18n.language) ? "right" : "left",
                },
              "& legend": {
                textAlign: isLangRTL(i18n.language) ? "right" : "left",
              },
            }}
          > */}
          <div className="grid grid-cols-3 gap-x-3 my-3">
            {nearByLocations?.length > 0 ? (
              nearByLocations?.map((location) => (
                <LocationCheckBox
                  key={location?.id}
                  location={location}
                  selectedLocations={selectedLocations}
                  setSelectedLocations={setSelectedLocations}
                  listingData={listingData}
                  setListingData={setListingData}
                />
              ))
            ) : (
              <p>{t("no_data_found")}</p>
            )}
          </div>
          {/* <FormControlLabel
              sx={{ marginBottom: "30px" }}
              control={
                <Checkbox
                  value={listingData?.is_featured}
                  onClick={(e) => {
                    const value = e.target.value;
                    const isTrue = value == 0 ? 1 : 0;
                    setListingData({
                      ...listingData,
                      is_featured: isTrue,
                    });
                  }}
                  fullWidth
                  inputProps={{ "aria-label": "controlled" }}
                  style={{
                    color: currentMode === "dark" ? "#fff" : "#000",
                  }}
                />
              }
              label={t("label_is_featured")}
            /> */}
          {/* </Box> */}
        </div>
      )}
    </div>
  );
};

export default AddListingNearBy;
